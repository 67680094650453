import { useEffect, useState } from "react";
import useMediaQuery from "./hooks/useMediaQuery";
import Navbar from "./scenes/Navbar"
import DotGroup from "./scenes/DotGroup"
import Landing from "./scenes/Landing"
import LineGradient from "./components/LineGradient"
import Experience from "./scenes/Experience"
import { motion } from "framer-motion"
import Footer from "./scenes/Footer"
import './index.css';

function App() {
    const [selectedPage, setSelectedPage] = useState('home');
    const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");
    const [isTopOfPage, setIsTopOfPage] = useState(true);
    useEffect(() =>{
        const handleScroll = () => {
            if (window.scrollY === 0) {
            setIsTopOfPage(true);
            setSelectedPage("home");};
            if (window.scrollY !== 0) setIsTopOfPage(false);
        }
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll)
    }, []);
    return  <div className="app bg-light-black">
        <Navbar
            isTopOfPage={isTopOfPage}
            selectedPage={selectedPage} setSelectedPage={setSelectedPage}
        />
      

        <div className = "w-5/6 mx-auto md:h-full">
            {isAboveMediumScreens && (
                <DotGroup
                    selectedPage={selectedPage} setSelectedPage={setSelectedPage}
                />
            )}
            
            <motion.div
                margin="0 0 -200px 0"
                amount="all"
                onViewportEnter={() => setSelectedPage("home")}
            >
                <Landing setSelectedPage={setSelectedPage} />
             </motion.div>
        </div>
        <LineGradient/>
        <div className = "w-5/6 mx-auto">
        <motion.div
          margin="0 0 -200px 0"
          amount="all"
          onViewportEnter={() => setSelectedPage("experience")}
        >
          <Experience />
        </motion.div>
        </div> 
        <LineGradient/>
        <Footer/>  
    </div>;
  
}

export default App;
